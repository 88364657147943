import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

// Create the Vue app
const app = createApp(App)

app.use(VueGtag, {
  config: { id: 'G-CC5EGRWWF4' },
  pageTrackerScreenview: true, // Optional: Track screenviews (pages) automatically
})

// Use the router and mount the app
app.use(router).mount('#app')
