<template>
    <div class=body>
        <ul class="navBar">

            <li class="nav-item" v-for="url in urls" :key="url.id" :ref="'nav-item_' + url.id">
                <router-link @click="sliderIndicator(url.id)" :to=url.link class="nav-item-url"
                    :class="url.id === selectedIndex ? 'active' : null">
                    {{ url.text }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'Navigation',
    data() {
        return {

            selectedIndex: 0,

            urls: [
                {
                    id: 1, text: 'Home', link: '/'
                },
                {
                    id: 2, text: 'People', link: '/people'
                },
                {
                    id: 3, text: 'Projects', link: '/projects'
                },
                {
                    id: 4, text: 'Publications', link: '/publications'
                },
                {
                    id: 5, text: 'Repository', link: '/repository'
                },
                {
                    id: 6, text: 'Press', link: '/recentPress'
                }


            ]
        }
    },
    methods: {
        sliderIndicator(id) {
            this.selectedIndex = id;

        },
        testmove(text) {
            this.$router.push({
                name: "test",
                query: {
                    testid: text
                }
            }
            )
        }
    }
}
</script>
<style>
/* colors */
:root {
    /* --active-color:#ffffff;
    --url-text-color:#747474; */
    --url-text-color: #ffffff;
    --active-color: #9b9999;
    --navBar-background-color: #000000;
    --active-background-color: #0065fd;
}

.body {
    text-align: center;
}

/* ul */
.navBar {
    float: left;
    min-width: 880px;
    margin-top: 42px;
    padding-left: 0px;
    height: 20px;
    background-color: var(--navBar-background-color);
    display: inline-flex;
    border-radius: 1px;
    list-style: inside;
}

/* li */
.nav-item {
    margin: auto;
    display: flex;
    text-decoration: none;
}

/* a */
.nav-item-url {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    display: inline-block;
    justify-content: space-around;
    align-items: center;
    color: var(--url-text-color);
    text-decoration: none;
}

.nav-item-url:hover,
.nav-item-url.active {
    color: var(--active-color);

}
</style>