
import {createRouter,createWebHistory} from 'vue-router'
                         
const routes = [
    {
        path:'/',
        component:()=>import('../views/Home.vue')
    },
    {
        path:'/people',
        component:()=>import('../views/People.vue')
    },
    {
        path:'/projects',
        component:()=>import('../views/Projects.vue'),
    },
    {
        path:'/projects/categories',
        component:()=>import('../views/ProjectCategory.vue')
    },
    {
        path:"/projects/categories/project",
        component:()=>import('../views/projectTemplate.vue')
    },
    {
        path:'/publications',
        component:()=>import('../views/Publication.vue')
    },
    {
        path:'/copyright',
        component:()=>import('../views/Copyright.vue')
    },
    {
        path:'/reference',
        component:()=>import('../views/Reference.vue')
    },
    {
        path:"/projects/categories/whatif",
        component:()=>import('../views/SpecialProject/Whatis.vue')
    },
    {
        path:"/recentPress",
        component:()=>import('../views/RecentPress.vue')
    },
    {
        path:"/repository",
        name:'Repository',
        component:()=>import('../views/Repository.vue')
    },
    {
        path:"/repository/AutoScene",
        component:()=>import('../views/Repository/AutoScene.vue')
    },
    {
        path:"/repository/COIL-20",
        component:()=>import('../views/Repository/COIL20.vue')
    },
    {
        path:"/repository/COIL-100",
        component:()=>import('../views/Repository/COIL100.vue')
    },
    {
        path:"/repository/ColumbiaGazeDataSet",
        component:()=>import('../views/Repository/ColumbiaGaze.vue')
    },
    {
        path:"/repository/Contaminants",
        component:()=>import('../views/Repository/Contaminants/main.vue')
    },
    {
        path:"/repository/DoRF",
        component:()=>import('../views/Repository/DoRF.vue')
    },
    {
        path:"/repository/EDGEVA",
        component:()=>import('../views/Repository/EDGEVA.vue')
    },
    {
        path:"/repository/FaceTracer",
        component:()=>import('../views/Repository/FaceTracer.vue')
    },
    {
        path:"/repository/LSD",
        component:()=>import('../views/Repository/LSD.vue')
    },
    {
        path:"/repository/NEARSEARCH",
        component:()=>import('../views/Repository/NEARSEARCH.vue')
    },
    {
        path:"/repository/RAFA",
        component:()=>import('../views/Repository/RAFA.vue')
    },
    {
        path:"/repository/Rain",
        component:()=>import('../views/Repository/Rain.vue')
    },
    {
        path:"/repository/SLAM",
        component:()=>import('../views/Repository/SLAM.vue')
    },
    {
        path:"/repository/Splash",
        component:()=>import('../views/Repository/Splash.vue')
    },
    {
        path:"/repository/VisualEyes",
        component:()=>import('../views/Repository/VisualEyes/main.vue')
    },
    {
        path:"/repository/VisualEyes/Tutorial",
        component:()=>import('../views/Repository/VisualEyes/tutorial.vue')
    },
    {
        path:"/repository/Contaminants/about",
        component:()=>import('../views/Repository/Contaminants/about.vue')
    },
    {
        path:"/repository/Contaminants/scattering",
        component:()=>import('../views/Repository/Contaminants/scattering.vue')
    },
    {
        path:"/repository/Contaminants/source",
        component:()=>import('../views/Repository/Contaminants/source.vue')
    },
    {
        path:"/repository/Contaminants/texture",
        component:()=>import('../views/Repository/Contaminants/texture.vue')
    },
    {
        path:"/repository/WILD",
        component:()=>import('../views/Repository/WILD/main.vue')
    },
    {
        path:"/repository/WILD/about",
        component:()=>import('../views/Repository/WILD/about.vue')
    },
    {
        path:"/repository/WILD/copyright",
        component:()=>import('../views/Repository/WILD/copyright.vue')
    },
    {
        path:"/repository/WILD/images",
        component:()=>import('../views/Repository/WILD/images.vue')
    },
    {
        path:"/repository/WILD/download",
        component:()=>import('../views/Repository/WILD/download.vue')
    },
    {
        path:"/repository/WILD/searchengine",
        component:()=>import('../views/Repository/WILD/searchengine.vue')
    },
    {
        path:"/repository/WILD/sponsor",
        component:()=>import('../views/Repository/WILD/sponsor.vue')
    },    {
        path:"/repository/WILD/videos",
        component:()=>import('../views/Repository/WILD/videos.vue')
    },
    {
        path:"/repository/CURET",
        component:()=>import('../views/Repository/CURET/main.vue')
    },
    {
        path:"/repository/CURET/about",
        component:()=>import('../views/Repository/CURET/about.vue')
    },
    {
        path:"/repository/CURET/sample",
        component:()=>import('../views/Repository/CURET/sample.vue')
    },
    {
        path:"/repository/CURET/btfm",
        component:()=>import('../views/Repository/CURET/BTFM.vue')
    },
    {
        path:"/repository/CURET/brdfm",
        component:()=>import('../views/Repository/CURET/BRDFM.vue')
    },
    {
        path:"/repository/CURET/brdfp",
        component:()=>import('../views/Repository/CURET/BRDFP.vue')
    },
    {
        path:"/repository/CURET/download",
        component:()=>import('../views/Repository/CURET/download.vue')
    },
    {
        path:"/repository/CURET/document",
        component:()=>import('../views/Repository/CURET/documentation.vue')
    },
    {
        path:"/repository/CURET/txtmap",
        component:()=>import('../views/Repository/CURET/textonly.vue')
    },
    {
        path:"/repository/CURET/graphmap",
        component:()=>import('../views/Repository/CURET/graphonly.vue')
    },
    {
        path:"/repository/CURET/table",
        component:()=>import('../views/Repository/CURET/table.vue')
    },
    {
        path:"/repository/CURET/cal",
        component:()=>import('../views/Repository/CURET/cal.vue')
    },
    {
        path:"/repository/CURET/cly",
        component:()=>import('../views/Repository/CURET/cly.vue')
    },
    {
        path:"/repository/CURET/spheres",
        component:()=>import('../views/Repository/CURET/spheres.vue')
    },
    {
        path:"/repository/CURET/spheres3",
        component:()=>import('../views/Repository/CURET/spheres3.vue')
    },
    {
        path:"/repository/CURET/spheres5",
        component:()=>import('../views/Repository/CURET/spheres5.vue')
    },
    {
        path:"/repository/CURET/spheres55",
        component:()=>import('../views/Repository/CURET/spheres55.vue')
    },
    {
        path:"/repository/CURET/over",
        component:()=>import('../views/Repository/CURET/over.vue')
    },
    {
        path:"/repository/PubFig",
        component:()=>import('../views/Repository/Pubfig/main.vue')
    },
    {
        path:"/repository/PubFig/download",
        component:()=>import('../views/Repository/Pubfig/download.vue')
    },
    {
        path:"/repository/PubFig/explore",
        component:()=>import('../views/Repository/Pubfig/explore.vue')
    },
    {
        path:"/repository/PubFig/results",
        component:()=>import('../views/Repository/Pubfig/result.vue')
    },
    {
        path:"/repository/RASCAL",
        component:()=>import('../views/Repository/RASCAL/main.vue')
    },
    {
        path:"/repository/RASCAL/about",
        component:()=>import('../views/Repository/RASCAL/about.vue')
    },
    {
        path:"/repository/RASCAL/software",
        component:()=>import('../views/Repository/RASCAL/software.vue')
    },
    {
        path:"/repository/RASCAL/gallery",
        component:()=>import('../views/Repository/RASCAL/gallery.vue')
    },
    {
        path:"/repository/STAF",
        component:()=>import('../views/Repository/STAF/main.vue')
    },
    {
        path:"/repository/STAF/about",
        component:()=>import('../views/Repository/STAF/about.vue')
    },
    {
        path:"/repository/STAF/db",
        component:()=>import('../views/Repository/STAF/db.vue')
    },
    {
        path:"/repository/STAF/documentation",
        component:()=>import('../views/Repository/STAF/documentation.vue')
    },
    {
        path:"/repository/STAF/samples",
        component:()=>import('../views/Repository/STAF/samples.vue')
    },
    {
        path:"/repository/TVBRDF",
        component:()=>import('../views/Repository/TVBRDF/main.vue')
    },
    {
        path:"/repository/TVBRDF/about",
        component:()=>import('../views/Repository/TVBRDF/about.vue')
    },
    {
        path:"/repository/TVBRDF/measures",
        component:()=>import('../views/Repository/TVBRDF/MandP.vue')
    },
    {
        path:"/repository/TVBRDF/documentation",
        component:()=>import('../views/Repository/TVBRDF/documentation.vue')
    },    {
        path:"/repository/TVBRDF/download",
        component:()=>import('../views/Repository/TVBRDF/download.vue')
    },
    {
        path:"/projects/categories/gigapixel",
        component:()=>import("../views/SpecialProject/gigapixel.vue")
    },
    {
        path:"/gallery/FibonacciHDR",
        component:()=>import("../views/Gallery/FibonacciHDR/main.vue")
    },
    {
        path:"/gallery/Diffuse_sl/main",
        component:()=>import("../views/Gallery/Diffuse_sl/main.vue")
    },
    {
        path:"/gallery/Diffuse_sl/pos1",
        component:()=>import("../views/Gallery/Diffuse_sl/pos1.vue")
    },
    {
        path:"/gallery/Diffuse_sl/pos2",
        component:()=>import("../views/Gallery/Diffuse_sl/pos2.vue")
    },
    {
        path:"/gallery/Diffuse_sl/gsr",
        component:()=>import("../views/Gallery/Diffuse_sl/gsr.vue")
    },
    {
        path:"/gallery/Diffuse_sl/qa",
        component:()=>import("../views/Gallery/Diffuse_sl/qa.vue")
    },
    {
        path:"/gallery/separation/main",
        component:()=>import("../views/Gallery/SeparationResult/main.vue")
    },
    {
        path:"/gallery/separation/DGSB",
        component:()=>import("../views/Gallery/SeparationResult/DiffuseGroove.vue")
    },
    {
        path:"/gallery/separation/RWO",
        component:()=>import("../views/Gallery/SeparationResult/WorldObjects.vue")
    },
    {
        path:"/gallery/separation/PFF",
        component:()=>import("../views/Gallery/SeparationResult/PlantsFlowersFood.vue")
    },
    {
        path:"/gallery/separation/SH",
        component:()=>import("../views/Gallery/SeparationResult/SkinHair.vue")
    },
    {
        path:"/gallery/separation/PSS",
        component:()=>import("../views/Gallery/SeparationResult/photometric.vue")
    },
    {
        path:"/gallery/separation/SO",
        component:()=>import("../views/Gallery/SeparationResult/Occluders.vue")
    },
    {
        path:"/gallery/separation/BTF",
        component:()=>import("../views/Gallery/SeparationResult/BTF.vue")
    },
    {
        path:"/gallery/separation/SS",
        component:()=>import("../views/Gallery/SeparationResult/SeparationScales.vue")
    },
    {
        path:"/gallery/separation/RF",
        component:()=>import("../views/Gallery/SeparationResult/RealFake.vue")
    },
    {
        path:"/gallery/separation/VRS",
        component:()=>import("../views/Gallery/SeparationResult/VRS.vue")
    },
    {
        path:"/gallery/micro_phase_shifting",
        component:()=>import("../views/Gallery/MicroPhaseShift/main.vue")
    },
    {
        path:"/repository/Multispectral",
        component:()=>import('../views/Repository/Multispectral/main.vue')
    },
    {
        path:"/repository/Multispectral/Stuff",
        component:()=>import('../views/Repository/Multispectral/Stuff.vue')
    },
    {
        path:"/repository/Multispectral/Paints",
        component:()=>import('../views/Repository/Multispectral/Paints.vue')
    },
    {
        path:"/repository/Multispectral/RealandFake",
        component:()=>import('../views/Repository/Multispectral/RealAndFake.vue')
    },
    {
        path:"/repository/Multispectral/SkinAndHair",
        component:()=>import('../views/Repository/Multispectral/SkinAndHair.vue')
    },
    {
        path:"/repository/Multispectral/FoodAndDrinks",
        component:()=>import('../views/Repository/Multispectral/FoodAndDrinks.vue')
    },
    {
        path:"/gallery/flexible_dof",
        component:()=>import('../views/Gallery/Flexible_DOF.vue')
    },
    {
        path:"/:catchAll(.*)",
        component:()=>import("../views/Home.vue")
    }

]

const router = createRouter({
    history:createWebHistory(),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
    routes})
export default router
