<template>

  <div id="app">
    <Header />
    <div class=content>
      <router-view :key="$route.fullPath" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"
export default {
  name: 'app',
  components: {
    Header,
    Footer,
  }

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600');

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  /* letter-spacing: .05em; */
}

#app {
  height: 100%;
  align-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  --font-family-sans-serif: '-apple-system', 'BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* --font-family-monospace: 'SFMono-Regular','Menlo,Monaco','Consolas',"Liberation Mono","Courier New",monospace; */
}

.content {
  /* letter-spacing: .05em; */
  padding-top: 16px;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  min-height: 80vh;
  height: fit-content;
  min-width: 1650px;
  font-family: '-apple-system', 'BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", 'SFMono-Regular', 'Menlo,Monaco', 'Consolas', "Liberation Mono", "Courier New", monospace;
}

.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 18px;
}

.table {
  margin: auto;
}
</style>
