<template>
    <div class="Footer">
        <a href="https://www.engineering.columbia.edu/"><img :src="require('@/assets/img/ColEng.png')" class='CE' /></a>
        <a href="https://www.columbia.edu/"><img :src="require('@/assets/img/Columbia.png')" class='CU' /></a>
        <a href="https://www.cs.columbia.edu/"><img :src="require('@/assets/img/CUCS.png')" class=CUCS></a>
    </div>
</template>

<script>
export default {
    name: 'Footer',

}
</script>

<style>
/* colors */
:root {
    --footer-background-color: #000000;
    --footer-text-color: #ffffff;
}

/* ul */
.Footer {
    bottom: 0;
    width: 100%;
    min-width: 1650px;
    height: 120px;
    background-color: var(--footer-background-color);
}

.CE {
    padding-top: 40px;
    width: 300px;
    height: 47px;

    ;
}

.CU {
    padding-top: 40px;
    padding-right: 60px;
    float: right;
    width: 300px;
    height: 47px;
}

.CUCS {
    padding-top: 40px;
    padding-left: 60px;
    float: left;
    width: 300px;
    height: 47px;
}
</style>